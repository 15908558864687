<template>
  <div>
    <div class="d-flex mb-2">
      <div class="pr-4 search noprint" style="width: 48%">
        <b-input-group size="sm">
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="search-form"
            type="search"
            v-model="input_search"
            placeholder="Buscar..."
          >
          </b-form-input>
          <b-input-group-append>
            <b-button :disabled="!input_search" @click="input_search = ''"
              >Limpiar</b-button
            >
          </b-input-group-append>
        </b-input-group>
        Disponibles
      </div>
      <div class="search noprint ml-2" style="width: 48%">
        <b-input-group size="sm">
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="search-form"
            type="search"
            v-model="input_search_2"
            placeholder="Buscar..."
          >
          </b-form-input>
          <b-input-group-append>
            <b-button :disabled="!input_search_2" @click="input_search_2 = ''"
              >Limpiar</b-button
            >
          </b-input-group-append>
        </b-input-group>
        Seleccionados
      </div>
    </div>
    <div class="tables-container">
      <div class="table-user-available mr-1">
        <b-table
          sticky-header="300px"
          :items="available_users"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="input_search"
          :filter-function="filterCustom"
          sort-icon-left
          responsive="sm"
          small
          hover
          fixed
          noCollapse
          bordered
          :selectable="allows_crud"
          select-mode="range"
          @row-selected="slotAvailableSelected"
          :caption-top="true"
        >
          <template #empty>
            <h5>Sin Data</h5>
          </template>
          <!-- <template v-slot:table-caption>Disponibles</template> -->
        </b-table>
      </div>
      <div
        :class="{ 'top-button': sortAvailableUser.length <= 3 }"
        class="buttons-container"
        v-if="allows_crud"
      >
        <b-button variant="info" @click="moveToSelected" class="mb-2" size="sm">
          <b-icon-arrow-right></b-icon-arrow-right>
        </b-button>
        <b-button variant="info" @click="moveToUnSelected" size="sm">
          <b-icon-arrow-left></b-icon-arrow-left>
        </b-button>
      </div>
      <div v-else class="mx-3"></div>
      <div class="table-user-selected ml-1">
        <b-table
          sticky-header="300px"
          :items="unavailable_users"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="currentPage"
          :filter="input_search_2"
          :filter-function="filterCustom2"
          :per-page="perPage"
          sort-icon-left
          responsive="sm"
          small
          hover
          fixed
          noCollapse
          bordered
          :selectable="allows_crud"
          select-mode="range"
          @row-selected="slotUnavailableSelected"
          :caption-top="true"
        >
          <!-- <template v-slot:table-caption>Seleccionados</template> -->
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "UserListSection",
  props: {
    section_id: {
      type: [Number, String],
    },
    filter_by_professor: {
      type: Boolean,
      default: false,
    },
    filter_by_student: {
      type: Boolean,
      default: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input_search: "",
      input_search_2: "",
      sortBy: "first_name",
      sortDesc: false,
      currentPage: 1,
      perPage: 40,
      available_users: [],
      selected_available: [],
      unavailable_users: [],
      selected_unavailable: [],
    };
  },
  computed: {
    ...mapGetters({
      users: names.USERS,
      sections: names.SECTIONS,
      egress_profiles: names.EGRESS_PROFILES,
    }),
    sortAvailableUser() {
      return this.available_users.filter(
        (x) =>
          this.$filtered(x.name, this.input_search) ||
          this.$filtered(
            this.getEgressProfileName(x.egress_profile),
            this.input_search
          )
      );
    },
    fields() {
      if (this.filter_by_student)
        return [
          { key: "name", sortable: true, label: "Nombre" },
          {
            key: "egress_profile",
            sortable: false,
            label: this.$getVisibleNames(
              "mesh.egressprofile",
              false,
              "Perfil de Egreso"
            ),
            formatter: (value) => this.getEgressProfileName(value),
          },
        ];
      else return [{ key: "name", sortable: true, label: "Nombre" }];
    },
    filteredUsers() {
      let filtered_list = this.users;
      if (this.filter_by_student) {
        filtered_list = filtered_list.filter((x) => x.is_student == true);
      }
      if (this.filter_by_professor) {
        filtered_list = filtered_list.filter((x) => x.is_professor == true);
      }
      let final_list = [];
      filtered_list.forEach((element) => {
        final_list.push({
          id: element.id,
          name:
            element.first_name || element.last_name
              ? (element.first_name + " " + element.last_name).trim()
              : element.email,
          egress_profile:
            element.student_card != null &&
            element.student_card.egress_profile != null
              ? element.student_card.egress_profile
              : null,
          _rowVariant: "none",
        });
      });
      return final_list;
    },
    section() {
      return this.sections.find((x) => x.id == this.section_id);
    },
  },
  methods: {
    filterCustom(row) {
      if (
        this.$filtered(row.name, this.input_search) ||
        this.$filtered(
          this.getEgressProfileName(row.egress_profile),
          this.input_search
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    filterCustom2(row) {
      if (
        this.$filtered(row.name, this.input_search_2) ||
        this.$filtered(
          this.getEgressProfileName(row.egress_profile),
          this.input_search_2
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    getEgressProfileName(value) {
      const egress_profile = this.egress_profiles.find((x) => x.id == value);
      if (egress_profile) return egress_profile.professional_title;
      return "";
    },
    fillUserLists() {
      this.available_users = [];
      this.unavailable_users = [];
      this.filteredUsers.forEach((user) => {
        if (this.section) {
          if (this.section.students.find((x) => x == user.id)) {
            this.unavailable_users.push(user);
          } else if (this.section.professors.find((x) => x == user.id)) {
            this.unavailable_users.push(user);
          } else {
            this.available_users.push(user);
          }
        } else {
          this.available_users.push(user);
        }
      });
    },
    slotAvailableSelected(rows) {
      this.selected_available = rows;
    },
    slotUnavailableSelected(rows) {
      this.selected_unavailable = rows;
    },
    moveToSelected() {
      this.selected_available.forEach((element) => {
        this.unavailable_users.push(element);
        let index = this.available_users.findIndex((x) => x.id == element.id);
        if (index != -1) this.available_users.splice(index, 1);
      });
      this.$emit("changed", this.unavailable_users);
    },
    moveToUnSelected() {
      this.selected_unavailable.forEach((element) => {
        this.available_users.push(element);
        let index = this.unavailable_users.findIndex((x) => x.id == element.id);
        if (index != -1) this.unavailable_users.splice(index, 1);
      });
      this.$emit("changed", this.unavailable_users);
    },
  },
  watch: {
    users() {
      this.fillUserLists();
    },
  },
  created() {
    this.fillUserLists();
    // if (this.filter_by_student) {
    //   this.$store.dispatch(names.FETCH_USERS).then((response) => {
    //     const map_student = [
    //       ...new Set(
    //         response
    //           .filter(
    //             (x) =>
    //               x.is_student == true &&
    //               x.student_card != null &&
    //               x.student_card.egress_profile != null
    //           )
    //           .map((x) => x.student_card.egress_profile)
    //       ),
    //     ];
    //     map_student.forEach((egress_profile_id) => {
    //       this.$store.dispatch(names.FETCH_EGRESS_PROFILE, egress_profile_id);
    //     });
    //   });
    // } else {
    // this.$store.dispatch(names.FETCH_USERS);
    // }
    if (this.section_id && !isNaN(this.section_id)) {
      this.$store.dispatch(names.FETCH_SECTION, this.section_id);
    }
  },
};
</script>

<style scoped>
.tables-container {
  display: flex;
  flex-direction: row;
}
.buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.top-button {
  margin-top: 5em;
}
.table-user-selected {
  width: 45%;
}
.table-user-available {
  width: 45%;
}
</style>